import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import Link from '@shared/ui/navigation/Link';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-light/alert-circle-light.svg';
import { BodyLarge } from '@src/components/styled/Typography';
import { getAbsolutePath } from '@src/app/routing/helpers';
import useAppNavigation from '@src/shared/lib/hooks/useAppNavigation';
import { EXCHANGE_NAMES } from '@shared/ui/sidebars/constants';
import { ErrorIcon, IconContainer, StepContainer, StepTitle, } from '../shared';
import useConnectExchange, { ConnectExchangeReqErrors } from '../useConnectExchange';
const Text = styled(BodyLarge)(props => ({
    textAlign: 'center',
    lineHeight: '24px',
    marginBottom: props.theme.spacing_sizes.l,
}));
const AdditionalText = styled(BodyLarge)(() => ({
    textAlign: 'center',
    lineHeight: '24px',
}));
const StepTitleStyled = styled(StepTitle)(props => ({
    marginBottom: props.theme.spacing_sizes.xs,
}));
const SupportText = () => {
    const { t } = useTranslation();
    const { routes } = useAppNavigation();
    return Trans({
        i18nKey: 'overview.connect_exchange.error.api_key_is_used.support_text',
        components: [
            _jsx(Link, { href: getAbsolutePath(routes.SUPPORT) }, 'support-link'),
        ],
        values: {
            linkText: t('overview.connect_exchange.error.api_key_is_used.support_link_text'),
        },
    });
};
const ApiErrorButtonText = ({ exchange = '', }) => (Trans({
    i18nKey: 'overview.connect_exchange.error.api_key_is_used.continue_btn',
    title: (exchange === null || exchange === void 0 ? void 0 : exchange.length) ? EXCHANGE_NAMES[exchange] : '',
}));
const Error = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { handleClose, sidebarFormValues, apiError } = useConnectExchange();
    const exchange = (sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.exchange) ? sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.exchange : '';
    const { title, text, additionalText, btnIcon, btnText, showCancelBtn, } = useMemo(() => {
        switch (apiError) {
            case ConnectExchangeReqErrors.ApiKeyIsUsed:
                return {
                    title: t('overview.connect_exchange.error.api_key_is_used.title'),
                    text: t('overview.connect_exchange.error.api_key_is_used.text'),
                    additionalText: _jsx(SupportText, {}),
                    btnText: _jsx(ApiErrorButtonText, { exchange: exchange || '' }),
                    showCancelBtn: true,
                };
            case ConnectExchangeReqErrors.ConnectionFailure:
                return {
                    title: t('overview.connect_exchange.error.connection_failure.title'),
                    text: t('overview.connect_exchange.error.connection_failure.text'),
                    btnText: t('overview.connect_exchange.error.connection_failure.continue_btn'),
                    btnIcon: AlertIcon,
                    showCancelBtn: true,
                };
            case ConnectExchangeReqErrors.UnknownError:
                return {
                    title: t('overview.connect_exchange.error.something_went_wrong.title'),
                    btnText: t('overview.connect_exchange.error.something_went_wrong.continue_btn'),
                };
            default:
                return {
                    title: '',
                    text: '',
                };
        }
    }, [t, apiError, exchange]);
    const goToExchange = () => {
        // TODO: implement logic
    };
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, displayCancelBtn: showCancelBtn, children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconContainer, { children: _jsx(ErrorIcon, {}) }), _jsx(StepTitleStyled, { children: title }), text && _jsx(Text, { children: text }), additionalText && _jsx(AdditionalText, { children: additionalText })] })), contentFlex: 1, buttons: (_jsx(Button, { startIcon: btnIcon, onClick: goToExchange, size: 'large', fullWidth: true, children: btnText })), containerPadding: '0' }) }));
};
export default Error;
